
<template>
  <div>
    <Loader />
    <!-- TOP Nav Bar END -->
    <div class="websiteWrapper">
      <nav-bar />
      <!-- TOP Nav Bar END -->
      <div  style="'transition: all 0.3s ease-out 0s;'">
        <div class="bg-warning-light breadcrumb_warning">
          <b-container>
            <BreadCrumb />
          </b-container>
        </div>
        <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
          <router-view/>
        </transition>
      </div>
    </div>
    <WebsiteFooter />
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapActions, mapGetters } from 'vuex'
import Loader from '../components/core/loader/Loader'

import SideBarItems from '../FackApi/json/SideBar'
import profile from '../assets/images/user/user-09.jpg'
// eslint-disable-next-line import/no-duplicates
import loader from '../assets/images/ibbil/goldLogo.svg'
// eslint-disable-next-line import/no-duplicates
import darkLoader from '../assets/images/ibbil/goldLogo.svg'
/*
import Lottie from '../components/core/lottie/Lottie'
*/
// import Customizer from './Components/Customizer'
/* import LayoutFixRightSide from './Components/LayoutFixRightSide' */
// eslint-disable-next-line import/no-duplicates
import WhiteLogo from '../assets/images/ibbil/goldLogo.svg'
/* import SmallSidebar from '../components/core/sidebars/SmallSidebar' */
import BreadCrumb from '../components/core/breadcrumbs/BreadCrumb'
/* import LayoutFooter from './Components/LayoutFooter' */
import WebsiteFooter from '@/components/core/footer/WebsiteFooter'
import NavBar from '../components/core/nav/NavBar.vue'
export default {
  name: 'websiteLayout',
  data () {
    return {
      search: '',
      userData: JSON.parse(localStorage.getItem('userInfo')),
      ifSearch: false,
      notFoundImage: require('../assets/images/error/search.png'),
      infiniteId: +new Date(),
      getNotifications: [],
      notification_unread: '',
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      verticalMenu: SideBarItems,
      userProfile: profile,
      onlyLogo: false,
      onlyLogoText: false,
      sidebarGroupTitle: true,
      logo: loader,
      rtl: false,
      SmallSidebarLogo: WhiteLogo,
      theme: 'light',
      message: [
        {
          image: require('../assets/images/user/user-01.jpg'),
          name: 'Nik Emma Watson',
          date: '13 jan'
        },
        {
          image: require('../assets/images/user/user-02.jpg'),
          name: 'Greta Life',
          date: '14 Jun'
        },
        {
          image: require('../assets/images/user/user-03.jpg'),
          name: 'Barb Ackue',
          date: '16 Aug'
        },
        {
          image: require('../assets/images/user/user-04.jpg'),
          name: 'Anna Sthesia',
          date: '21 Sept'
        },
        {
          image: require('../assets/images/user/user-05.jpg'),
          name: 'Bob Frapples',
          date: '29 Sept'
        }
      ],
      notification: [
        {
          image: require('../assets/images/user/user-01.jpg'),
          name: 'Nik Emma Watson',
          date: '23 hour ago',
          description: 'Enjoy smart access to videos, games'
        },
        {
          image: require('../assets/images/user/user-02.jpg'),
          name: 'Greta Life',
          date: '14 hour ago',
          description: 'Google Chromecast: Enjoy a world of entertainment'
        },
        {
          image: require('../assets/images/user/user-03.jpg'),
          name: 'Barb Ackue',
          date: '16 hour ago',
          description: 'Dell Inspiron Laptop: Get speed and performance from'
        },
        {
          image: require('../assets/images/user/user-04.jpg'),
          name: 'Anna Sthesia',
          date: '21 hour ago',
          description: 'Deliver your favorite playlist anywhere in your home '
        },
        {
          image: require('../assets/images/user/user-05.jpg'),
          name: 'Bob Frapples',
          date: '11 hour ago',
          description:
            'MacBook Air features up to 8GB of memory, a fifth-generation'
        }
      ],
      notBookmarkRouts: [
        'dashboard.home-1',
        'dashboard.home-2',
        'dashboard.home-3',
        'dashboard.home-4',
        'dashboard.home-5',
        'dashboard.home-6'
      ],
      currentPage: 2,
      readNotification: ''
    }
  },
  components: {
    /*    LayoutFooter, */
    /*    LayoutFixRightSide, */
    /* Lottie */
    Loader,
    /*    Sidebar,
    DefaultNavBar,
    SmallSidebar */
    /*
    BreadCrumb
*/
    BreadCrumb,
    WebsiteFooter,
    NavBar
  },
  mounted () {
    this.layoutSetting(this.$route.name)
    this.modeChange({ rtl: true, dark: this.darkMode })
  },
  computed: {
    ...mapGetters({
      /* cartCount: 'Ecommerce/cartCountState',
      cartItems: 'Ecommerce/cartState', */
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      darkMode: 'Setting/darkModeState',
      rtlMode: 'Setting/rtlModeState',
      colors: 'Setting/colorState'
      /* getDefaultProject: 'getDefaultProject' */
    }),
    toggleSideIcon () {
      let show = true
      switch (this.$route.name) {
        case 'dashboard.home-3':
        case 'dashboard.home-4':
        case 'dashboard.home-5':
        case 'dashboard.home-6':
          show = false
          break
      }
      return show
    }
  },
  watch: {
    $route: function (to, from) {
      this.saveBeforeRoute(from.name)
      this.layoutSetting(to.name)
    }
  },
  // sidebarTicket
  methods: {
    themeMode (mode) {
      this.dark = mode
      this.modeChange({ rtl: this.rtl, dark: mode })
      if (mode) {
        this.logo = darkLoader
        this.theme = 'light'
      } else {
        this.logo = loader
        this.theme = 'dark'
      }
      this.$emit('onLogo', this.logo)
    },
    rtlChange (mode) {
      this.rtl = mode
      this.modeChange({ rtl: mode, dark: this.darkMode })
      if (this.darkMode) {
        this.logo = darkLoader
      } else {
        this.logo = loader
      }
    },
    layoutSetting (routeName) {
      this.modeChange({ rtl: this.rtlMode, dark: this.darkMode })
      this.onlyLogo = true
      this.onlyLogoText = true
      this.sidebarGroupTitle = true
      switch (routeName) {
        case 'dashboard.home-5':
          this.onlyLogoText = true
          this.onlyLogo = false
          break
        case 'dashboard.home-6':
          this.logo = WhiteLogo
          this.onlyLogo = true
          this.onlyLogoText = false
          this.sidebarGroupTitle = false
          break
        default:
          if (this.darkMode) {
            this.logo = darkLoader
          } else {
            this.logo = loader
          }
          break
      }
    },
    changeLogo (e) {
      this.logo = e
    },
    sidebarMini () {
      core.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
    },
    logout () {},
    /*    langChange (lang) {
      this.langChangeState(lang)
      document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
      if (lang.value === 'ar') {
        this.modeChange({ rtl: true, dark: this.darkMode })
        this.$i18n.locale = 'en'
        this.rtlAdd(lang)
      } else {
        this.$i18n.locale = 'ar'
        this.modeChange({ rtl: false, dark: this.darkMode })
        this.rtlRemove(lang)
      }
    }, */
    langChange (lang) {
      this.langChangeState(lang)
      this.$i18n.locale = lang.value
      document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
      if (lang.value === 'ar') {
        this.rtlAdd(lang)
      } else {
        this.rtlRemove(lang)
      }
    },
    routerAnimationChange (e) {
      this.animated = e
    },
    ...mapActions({
      removeToCart: 'Ecommerce/removeToCartAction',
      langChangeState: 'Setting/setLangAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction',
      modeChange: 'Setting/layoutModeAction'
    })
  },
  created () {}
}
</script>
<style>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");
.unread {
  left: -16px;
  top: 5px;
  font-size: 9px;
}
.mark {
  background-color: #e3e5fc !important;
}
.allNotifications {
  overflow: auto;
  height: 30rem;
}
</style>
