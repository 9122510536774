<template>
  <footer class=" bg-white iq-footer bg-primary mx-0 main-footer">
    <b-container>
      <b-row class="pt-4">
        <b-col lg="4">
          <img :src='footerImage' alt="ibbil" />
          <p class="text-white mt-4 font-size-12">
            {{$t('endUser.footer')}}
          </p>
          <ul class="d-flex p-0 font-size-24" style="gap: 20px">
            <li><a><i class="lab la-twitter text-white"></i></a></li>
            <li><a><i class="lab la-linkedin-in text-white"></i></a></li>
            <li><a><i class="lab la-instagram text-white"></i></a></li>
            <li><a><i class="lab la-facebook-f text-white"></i></a></li>
            <li><a><i class="lab la-whatsapp text-white"></i></a></li>
          </ul>
        </b-col>
        <b-col cols="8" lg="3" class="">
          <div>
            <h3 class="text-white mt-0 mt-0 mb-3 position-relative header-website-footer">
              {{$t('endUser.shops')}}
            </h3>
            <ul  v-for="(category, key) in allCategories" :key="key" class="p-0 mb-0 font-size-16 footer-menu" style="gap: 20px">
              <router-link v-if="key + 1 != allCategories.length" :to="{name: 'allMarketplaces' , params: {id:category.slug}}" tag="li">{{category.name}}</router-link>
              <router-link v-else to="/stores/merchants/electronic_chip/store-profile/e_ship/" tag="li">{{category.name}}</router-link>
            </ul>
          </div>
        </b-col>
        <b-col cols="4" lg="2" class="">
          <div>
          <h3 class="text-white mt-0 mt-0 mb-3 position-relative position-relative header-website-footer">
            {{$t('endUser.services')}}
          </h3>
          <ul class="p-0 font-size-16 footer-menu" style="gap: 20px">
            <router-link :to="{name: 'permissions'}" tag="li"> {{ $t('endUser.permissions') }}</router-link>
            <router-link :to="{name: 'insurance'}" tag="li"> {{ $t('endUser.insurance') }}</router-link>
            <router-link :to="{name: 'transportation'}" tag="li"> {{ $t('endUser.transport') }}</router-link>
            <router-link :to="{name: 'veterinarian'}" tag="li"> {{ $t('endUser.veterinarian') }}</router-link>
          </ul>
          </div>
        </b-col>
        <b-col lg="3" class="d-flex justify-content-center ">
          <div class="">
          <h3 class="text-white mt-0 mt-0 position-relative header-website-footer mb-3">
            {{$t('endUser.termsConditions')}}
          </h3>
          <ul class="p-0 font-size-16 footer-menu" style="gap: 20px">
            <router-link :to="{name: 'termsCondition'}" tag="li">{{ $t('endUser.termsCondition') }}</router-link>
            <router-link :to="{name: 'privacyPolicy'}" tag="li">{{ $t('endUser.privacyPolicy') }}</router-link>
          </ul>
            <img class="m-auto" :src="require('@/assets/images/ibbil/vision.svg')">
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!--Copyright-->
    <b-container>
      <div class="d-flex justify-content-center copyrightsFooter">
        <p class="m-0">{{$t('endUser.reserved')}}</p>
      </div>

    </b-container>
    <!--/.Copyright-->

  </footer>
</template>
<script>
import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
export default {
  data () {
    return {
      footerImage: require('@/assets/images/ibbil/logo.svg'),
      allCategories: []
    }
  },
  methods: {
    getAllCategories () {
      marketPlace.getAllStoresGategories().then(res => {
        this.allCategories = res.data.data
      })
    }
  },
  created () {
    this.getAllCategories()
  }
}
</script>

<style>
@media(max-width:979px) {
  .main-footer {
    padding-bottom: 120px !important;
  }
}
</style>
