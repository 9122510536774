<template>
  <!-- :placeholder="$t('endUser.storeType')" -->
  <div class="position-relative d-flex align-items-center search-wrapper flex-grow-1">
    <div class="d-flex align-items-center search-wrapper flex-grow-1">
      <main-select
        :reduce="category => category.value"
        :options="allCategories"
        v-model="category"
        label="label"
        dir="rtl"
        class="m-0"
      />
      <div class="inputContainer d-flex align-items-center flex-grow-1">
        <b-input
        :placeholder="$t('endUser.searchInAllDepartments')"
        class="bg-transparent border-0 flex-grow-1 min-width-5 text-muted"
        v-model="search"
        @focus="onFocus"
        @input="handleChange"
        ></b-input>
        <i class="las la-search text-gray font-size-20"></i>
      </div>
    </div>
    <div class="search-listing" v-if="(isFocused)">
      <!-- start header  -->
      <!-- <div class="search-listing-header d-flex align-items-center justify-content-between">
        <b-button class="advanced-search d-flex align-items-center bg-transparent p-0 border-0 m-0 font-size-18" @click="openAdvancedSearch">
          <span class="icon d-flex align-items-center justify-content-center" :style="{backgroundColor: advancedSearch ? 'var(--iq-bg-dark-bg)' : ''}">
            <i class="las la-filter m-0"></i>
          </span>
          <span class="text">بحث متقدم</span>
        </b-button>
        <b-button class="d-flex align-items-center bg-transparent p-0 border-0 m-0 back-btn" v-if="advancedSearch" @click="closeAdvancedSearch">
          <i class="las la-long-arrow-alt-left icon"></i>
        </b-button>
      </div> -->
      <!-- body  recent -->
      <div class="search-listing-recent mt-4" v-if="(recentSearch.length && !search && !advancedSearch)">
        <h5 class="search-title">اخر النتائج</h5>
        <ul class="search-listing-recent-items list-unstyled p-0">
          <li class="search-listing-recent-item d-flex align-items-center justify-content-between" v-for="item in recentSearch" :key="item.id">
            <h5 @click="handleRecentSearch(item)" class="search-listing-recent-item-title">{{item.title}}</h5>
            <b-button class="bg-transparent p-0 border-0 search-listing-recent-item-close" @click="removeRecentSearch(item.id)">
              <i class="las la-times icon"></i>
            </b-button>
          </li>
        </ul>
      </div>
      <!-- body  advanced search -->
      <div class="search-listing-advanced mt-4" v-if="advancedSearch">
        advanced search
      </div>
      <!-- body  results -->
      <div class="search-listing-result mt-4">
        <ul class="search-listing-result-items list-unstyled p-0" v-if="(searchResult.length && search)">
          <li
            v-for="(item, index) in searchResult.slice(0, 5)"
            :key="index"
            @click="handleSearchItem(item)"
            class="search-listing-result-item d-flex align-items-center">
            <img :src="item.image" alt="ibiil img" class="img-fluid" />
            <div class="content d-flex justify-content-between align-items-end flex-fill">
              <div>
                <h6 class="search-listing-result-item-title">{{item.title ? item.title.substring(0, 50) + '...' : ''}}</h6>
              </div>
              <div>
                <h6 class="search-listing-result-item-category d-flex align-items-center">
                  <span class="t">القسم:</span>
                  <span>{{$t(`main.${item.module}`)}}</span>
                </h6>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="searchLoading" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
      <!-- footer  -->
      <div class="search-footer d-flex align-items-center">
        <h5 v-if="!searchResult.length && search && !searchLoading" class="show-more-btn flex-fill text-center">لا توجد نتائج بحث</h5>
        <router-link tag="h5" :to="{name: 'search', query: {search: search, category: category}}" class="show-more-btn flex-fill text-center" v-if="searchResult.length">عرض كل النتائج</router-link>
        <b-button variant="primary" class="close-search-btn" @click="closeSearch">اغلاق مربع البحث</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/services/main.js'
import { debounce } from 'debounce'
import commonMixin from '@/mixins/commonMixin'
export default {
  mixins: [commonMixin],
  data () {
    return {
      searchLoading: false,
      search: '',
      isFocused: false,
      allCategories: [
        { label: 'الكل', value: '' },
        { label: 'الويب سايت', value: 'website' },
        { label: 'التامين', value: 'insurance' },
        { label: 'المتاجر', value: 'marketplace' },
        { label: 'النقل', value: 'transportation' },
        { label: 'البيطرة', value: 'veterinary' }
      ],
      category: '',
      // recentSearch: localStorage.getItem('searchedItems') ? JSON.parse(localStorage.getItem('searchedItems')) : [],
      searchResult: [],
      advancedSearch: false
    }
  },
  methods: {
    onFocus () {
      this.isFocused = true
    },
    removeRecentSearch (id) {
      this.recentSearch = this.recentSearch.filter(item => item.id !== id)
      localStorage.setItem('searchedItems', JSON.stringify(this.recentSearch))
    },
    openAdvancedSearch () {
      this.advancedSearch = true
    },
    closeAdvancedSearch () {
      this.advancedSearch = false
    },
    closeSearch () {
      this.search = ''
      this.isFocused = false
      this.advancedSearch = false
    },
    debounceApi: debounce((payload, that) => {
      api.getGlobalSearch(payload).then((res) => {
        that.searchResult = res.data
      }).finally(() => {
        that.searchLoading = false
      })
    }, 500),
    handleChange (value) {
      if (value) {
        this.searchLoading = true
        const payload = this.category ? {
          search: value,
          modules: [this.category]
        } : {
          search: value
        }
        this.debounceApi(payload, this)
      }
    },
    handleSearchItem (item) {
      // this.searchList(item)
      this.closeSearch()
      if (item.table === 'stores') {
        this.$router.push(`/stores/merchants/${item.category}/store-profile/${item.url}`)
      } else if (item.table === 'products') {
        this.$router.push(`/stores/merchants/${item.category}/store-profile/${item.store}/products/${item.id}`)
      } else { }
      // this.$router.push(`/${item.table}/${item.id}`)
      // get search items from localstorage
      // const getSearchedItems = localStorage.getItem('searchedItems')
      // if (getSearchedItems) {
      //   const parsing = JSON.parse(getSearchedItems)
      //   const findItem = parsing.find(i => parseInt(i.id) === parseInt(item.id) && i.table === item.table)
      //   if (!findItem) {
      //     this.recentSearch.unshift(item)
      //     localStorage.setItem('searchedItems', JSON.stringify([item, ...parsing]))
      //   }
      // } else {
      //   localStorage.setItem('searchedItems', JSON.stringify([item]))
      // }
      // this.closeSearch()
      // this.$router.push(`/${item.table}/${item.id}`)
    },
    handleRecentSearch (item) {
      this.closeSearch()
      if (item.table === 'stores') {
        this.$router.push(`/stores/merchants/${item.category}/store-profile/${item.url}`)
      } else if (item.table === 'products') {
        this.$router.push(`/stores/merchants/${item.category}/store-profile/${item.store}/products/${item.id}`)
      } else { }
    }
  },
  created () {
    window.addEventListener('click', (e) => {
      if (!e.target.closest('.search-wrapper') && !e.target.classList.contains('icon')) {
        this.isFocused = false
      }
    })
  }
}
</script>

<style lang="scss">
.search-wrapper {
  background-color: #fff;
  border-radius: 30px;
  max-width: 750px;
  .vs__dropdown-menu {
    left: auto !important;
    right: 0 !important;
    top: calc(100% + 5px);
    // border-start-start-radius: 20px;
  }
  .vs__dropdown-toggle {
    border: none !important;
    border-inline-end: 1px solid rgba(0 0 0 / .2) !important;
    background-color: transparent;
    border-radius: 0 !important;
    min-width: 120px;
    color: #333;
    padding: 0 10px 0 5px;
  }

  .vs__actions {
    line-height: 0;
  }

  .inputContainer {
    padding: 0 15px;

    .form-control {
      padding: 0;
    }
  }
  .search-listing {
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    position: absolute;
    top: calc( 100% + 5px );
    width: 100%;
    left: 0;
    background-color: #fff;
    border-radius: 30px;
    color: #333;
    padding: 20px;
    z-index: 99;

    .search-listing-header {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0 0 0 / .2);
      .advanced-search {
        color: #9D9D9D;
        .icon {
          width: 35px;
          height: 35px;
          border-radius: 8px;
          background: #707070;
          font-size: 20px;
          color: #fff;
          font-size: 20px;
          margin-inline-end: 10px;
        }
      }
      .back-btn {
        color: #9D9D9D;
        .icon {
          font-size: 25px;
        }
      }
    }

    .search-title {
      color: #9D9D9D;
      margin-bottom: 20px;
    }

    .search-listing-recent-item:not(:last-child), .search-listing-result-item:not(:last-child) {
      margin-bottom: 15px;
      // border-bottom: 1px solid rgba(0 0 0 / .2);
    }

    .search-listing-recent-item {
      padding: 20px !important;
    }

    .search-listing-recent-item, .search-listing-result-item{
      padding: 5px 20px;
      background-color: #eee;
      border-radius: 5px;
      cursor: pointer;
    }

    .search-listing-recent-item-title, .search-listing-result-item-title {
      color: #333;
    }

    .search-listing-recent-item-close, .search-listing-recent-item-close:hover {
      color: var(--iq-bg-dark-bg);
    }

    .search-listing-result-item {
      img {
        width: 60px;
        min-height: 60px;
        object-fit: cover;
        border-radius: 10px;
        margin-inline-end: 20px;
        border-radius: 50%;
      }

      .search-listing-result-item-title {
        margin-bottom: 10px;
      }
      .search-listing-result-item-category {
        .t {
          margin-inline-end: 10px;
        }
      }
    }
  }
  .search-footer {
    margin-top: 20px;
    .show-more-btn {
      margin-inline-start: 100px;
      cursor: pointer;
      color: #333;
      font-weight: bold;
      line-height: 0;
    }
  }
}
</style>
