<template>
  <nav>
    <div class="nav-desketop">
      <main-menu :main-menu="mainMenuItems" />
      <second-menu :second-menu="secondMenuItems" />
    </div>
    <mobile-nav :listItems="[...mainMenuItems , ...secondMenuItems]" />
  </nav>
</template>

<script>
import MobileNav from './MobileNav/MobileNav.vue'
import MainMenu from './NavDesketop/MainMenu.vue'
import SecondMenu from './NavDesketop/SecondMenu.vue'
export default {
  components: { MobileNav, SecondMenu, MainMenu },
  data () {
    return {
      mainMenuItems: [
        { name: 'endUser.home', pathName: 'home' },
        { name: 'endUser.aboutUs', pathName: 'aboutUs' },
        // { name: 'endUser.services', pathName: 'services' },
        // { name: 'endUser.mediaCenter', pathName: 'mediaCenter' },
        { name: 'endUser.contactUs', pathName: 'contactUs' }
      ],
      secondMenuItems: [
        {
          name: 'marketplace.stores',
          pathName: 'stores',
          /*           nestedItems: [
            {
              name: 'marketplace.allStores',
              pathName: 'stores'
            },
            {
              name: 'marketplace.camelStores',
              pathName: 'stores',
              routeParameter: 'camel'
            },
            {
              name: 'marketplace.feedStores',
              pathName: 'stores',
              routeParameter: 'feed'
            },
            {
              name: 'marketplace.camelProductsStores',
              pathName: 'stores',
              routeParameter: 'camelProducts'
            },
            {
              name: 'marketplace.animalFoodStores',
              pathName: 'stores',
              routeParameter: 'animalFood'
            },
            {
              name: 'marketplace.camelSuppliesStores',
              pathName: 'stores',
              routeParameter: 'camelSupplies'
            },
            {
              name: 'marketplace.electronicChipStores',
              pathName: 'stores',
              routeParameter: 'electronicChip'
            }
          ], */
          icon: `<svg
            fill="white"
            id="store"
            xmlns="http://www.w3.org/2000/svg"
            width="20.341"
            height="17.799"
            viewBox="0 0 20.341 17.799"
          >
            <><g id="Group_134" data-name="Group 134" transform="translate(0 0)">
              <g id="Group_133" data-name="Group 133">
                <path
                  id="Path_118"
                  data-name="Path 118"
                  d="M20.322,37.567l-1.151-4.6A1.27,1.27,0,0,0,17.938,32H2.4a1.273,1.273,0,0,0-1.234.962l-1.151,4.6A.622.622,0,0,0,0,37.721,3.105,3.105,0,0,0,3.019,40.9,2.967,2.967,0,0,0,5.4,39.67a2.926,2.926,0,0,0,4.767,0,2.926,2.926,0,0,0,4.767,0A2.965,2.965,0,0,0,17.322,40.9a3.105,3.105,0,0,0,3.019-3.178A.622.622,0,0,0,20.322,37.567Z"
                  transform="translate(0 -32)" />
              </g>
            </g><g
              id="Group_136"
              data-name="Group 136"
              transform="translate(1.271 9.423)"
            >
                <g id="Group_135" data-name="Group 135">
                  <path
                    id="Path_119"
                    data-name="Path 119"
                    d="M48.051,269.932a4.159,4.159,0,0,1-2.384-.748,4.278,4.278,0,0,1-4.767,0,4.278,4.278,0,0,1-4.767,0,4.127,4.127,0,0,1-4.132.352v6.752a1.272,1.272,0,0,0,1.271,1.271h5.085v-5.085h5.085v5.085h5.085a1.272,1.272,0,0,0,1.271-1.271v-6.752A4.125,4.125,0,0,1,48.051,269.932Z"
                    transform="translate(-32 -269.184)" />
                </g>
              </g></>
          </svg>`
        },
        {
          name: 'endUser.insurance',
          pathName: 'insurance',
          /*  nestedItems: [
            {
              name: 'حول التأمين',
              pathName: 'aboutInsurance'
            },
            {
              name: 'حول التأمين',
              pathName: 'aboutInsurance'
            }
          ], */
          icon: `<svg
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            width="19.833"
            height="17.799"
            viewBox="0 0 19.833 17.799"
          >
            <path
              id="Path_178"
              data-name="Path 178"
              d="M38.39,25.933c2.66-2.5,5.963-5.609,6.417-8.375a6.035,6.035,0,0,0-.925-4.791A5.961,5.961,0,0,0,39.5,10.612a5.385,5.385,0,0,0-.544-.028A6.372,6.372,0,0,0,35.011,12a6.372,6.372,0,0,0-3.948-1.42,5.389,5.389,0,0,0-.544.028,5.959,5.959,0,0,0-4.378,2.156,6.032,6.032,0,0,0-.925,4.791c.454,2.765,3.756,5.872,6.416,8.375,0,0,1.327,1.331,1.726,1.673a2.8,2.8,0,0,0,1.654.776,2.8,2.8,0,0,0,1.654-.776C37.064,27.265,38.39,25.933,38.39,25.933Zm-6.757-12.05a2.922,2.922,0,0,0-3.074,2.734.828.828,0,1,1-1.657,0,4.575,4.575,0,0,1,4.73-4.391.828.828,0,1,1,0,1.657Z"
              transform="translate(-25.094 -10.584)"
            />
          </svg>`
        },
        {
          name: 'endUser.transport',
          pathName: 'transportation',
          /*  nestedItems: ['حول نقل الإبل', 'قدم طلب نقل'], */
          icon: `<svg
            fill="white"
            id="delivery-truck_1_"
            data-name="delivery-truck (1)"
            xmlns="http://www.w3.org/2000/svg"
            width="26.39"
            height="17.799"
            viewBox="0 0 26.39 17.799"
          >
            <g id="Group_145" data-name="Group 145" transform="translate(0 0)">
              <g
                id="Group_144"
                data-name="Group 144"
                transform="translate(0 0)"
              >
                <path
                  id="Path_133"
                  data-name="Path 133"
                  d="M0,233.488a.459.459,0,0,0,.46.483h.735a3.254,3.254,0,0,1,2.923-1.913,3.254,3.254,0,0,1,2.923,1.913H16.59v-3.907H0Z"
                  transform="translate(0 -219.624)"
                />
                <path
                  id="Path_134"
                  data-name="Path 134"
                  d="M32.638,277.32a2.166,2.166,0,1,0,2.166,2.166A2.166,2.166,0,0,0,32.638,277.32Z"
                  transform="translate(-28.52 -263.853)"
                />
                <path
                  id="Path_135"
                  data-name="Path 135"
                  d="M283.493,191.971l-2.062-.907H275v6.405h1.248a3.189,3.189,0,0,1,5.845,0h1.222a.459.459,0,0,0,.46-.483v-4.55A.5.5,0,0,0,283.493,191.971Z"
                  transform="translate(-257.386 -183.122)"
                />
                <path
                  id="Path_136"
                  data-name="Path 136"
                  d="M279.31,121.852a1.471,1.471,0,0,0-1.328-.791H275v3.459h5.7Z"
                  transform="translate(-257.386 -117.602)"
                />
                <path
                  id="Path_137"
                  data-name="Path 137"
                  d="M15.119,67.064H1.463A1.476,1.476,0,0,0,0,68.566V76.48H16.59V68.566s0,0,0-.007A1.483,1.483,0,0,0,15.119,67.064Z"
                  transform="translate(0 -67.064)"
                />
                <path
                  id="Path_138"
                  data-name="Path 138"
                  d="M308.471,277.32a2.166,2.166,0,1,0,2.166,2.166h0A2.166,2.166,0,0,0,308.471,277.32Z"
                  transform="translate(-286.686 -263.853)"
                />
              </g>
            </g>
          </svg>`
        },
        {
          name: 'endUser.veterinarian',
          pathName: 'veterinarian',
          /*   nestedItems: ['حول خدمات البيطرة', 'العيادات', 'الاطباء', 'حجز موعد'], */
          icon: `<svg
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            width="17.799"
            height="17.799"
            viewBox="0 0 17.799 17.799"
          >
            <path
              id="doctor-bag"
              d="M18.821,6.955H16.843V5.966A2.966,2.966,0,0,0,13.877,3H9.922A2.966,2.966,0,0,0,6.955,5.966v.989H4.978A1.984,1.984,0,0,0,3,8.933v9.888A1.984,1.984,0,0,0,4.978,20.8H18.821A1.984,1.984,0,0,0,20.8,18.821V8.933a1.984,1.984,0,0,0-1.978-1.978ZM8.933,5.966a.989.989,0,0,1,.989-.989h3.955a.989.989,0,0,1,.989.989v.989H8.933Zm5.933,8.9H12.888v1.978H10.91V14.866H8.933V12.888H10.91V10.91h1.978v1.978h1.978Z"
              transform="translate(-3 -3)"
              fill="#fff"
            />
          </svg>`
        },
        {
          name: 'endUser.permissions',
          pathName: 'permissions',
          /*  nestedItems: ['عن التصاريح', 'الحصول علي تصريح', 'التحقق من التصاريح'], */
          icon: `<svg
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            width="17.169"
            height="19.229"
            viewBox="0 0 17.169 19.229"
          >
            <path
              id="secure"
              d="M29.232,2.692a.629.629,0,0,0-.454-.519L21.049.023a.628.628,0,0,0-.337,0L12.984,2.173a.628.628,0,0,0-.454.519c-.045.322-1.067,7.938,1.556,11.727a12.339,12.339,0,0,0,6.648,4.793.63.63,0,0,0,.3,0,12.338,12.338,0,0,0,6.648-4.793C30.3,10.63,29.277,3.014,29.232,2.692ZM25.865,7.138,20.593,12.41a.628.628,0,0,1-.889,0L16.445,9.15a.628.628,0,0,1,0-.889l.647-.647a.628.628,0,0,1,.889,0l2.168,2.168,4.18-4.18a.628.628,0,0,1,.889,0l.647.647A.628.628,0,0,1,25.865,7.138Z"
              transform="translate(-12.296 -0.001)"
            />
          </svg>`
        },
        {
          // name: 'endUser.camelTrade',
          // pathName: 'camelTrade',
          name: 'endUser.services',
          pathName: 'services',
          /*  nestedItems: ['تداول الإبل', 'مربو الإبل', 'المتاجر', 'مبيعات الإبل', 'تأجير و مشاركة الإبل'], */
          icon: `<svg
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="19"
            height="19"
            viewBox="0 0 19 19"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_505"
                  data-name="Rectangle 505"
                  width="19"
                  height="19"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g
              id="Group_245"
              data-name="Group 245"
              transform="translate(0.495 0.495)"
            >
              <g
                id="Group_245-2"
                data-name="Group 245"
                transform="translate(-0.495 -0.495)"
                clip-path="url(#clip-path)"
              >
                <path
                  id="Path_175"
                  data-name="Path 175"
                  d="M71.373,91.45c-.1.764-.092,1.587-.153,1.852-.113.48-.76,2.957-.76,2.957h1.564a12.258,12.258,0,0,0,.182-2.43c-.023-.245-.833-2.38-.833-2.38"
                  transform="translate(-59.916 -77.896)"
                  fill="#fff"
                />
                <path
                  id="Path_176"
                  data-name="Path 176"
                  d="M87.828,75.4c-.191.09-.456.227-.824.432a4.5,4.5,0,0,1-2.714.472c-.539-.01-.857,0-1.12.007l.674,3.617c-.1.7-.087,2.583-.087,2.583H86.9A1.306,1.306,0,0,0,88.2,81.2c0-.738-.009-1.514-.009-1.72,0-.392-.368-4.08-.368-4.081"
                  transform="translate(-70.803 -64.147)"
                  fill="#fff"
                />
                <path
                  id="Path_177"
                  data-name="Path 177"
                  d="M15.156,0H2.764A2.764,2.764,0,0,0,0,2.764V15.156A2.764,2.764,0,0,0,2.764,17.92h6.6A24.505,24.505,0,0,0,9.9,14.962l-.067-2a6.466,6.466,0,0,0-.853-2.106c-.245-.582-.9-.595-1.694-.454A2.552,2.552,0,0,1,4.792,9.391,9.563,9.563,0,0,1,3.515,6.9a8.918,8.918,0,0,1-1.164-.245,1.422,1.422,0,0,0-.85.023.556.556,0,0,1-.315.036c-.237-.07-.211-.232-.211-.232a.676.676,0,0,1,.079-.82,1.077,1.077,0,0,1,.585-.5c.394-.118,1.578-.417,1.578-.417A1.471,1.471,0,0,1,3.95,4.49a.622.622,0,0,0,.45-.222.67.67,0,0,1,.249-.211c.3-.079.368.106.224.262-.095.1-.086.624-.086.624.571.71.985,1.667,1.5,2.256h0a.192.192,0,0,0,.237.039,3.552,3.552,0,0,0,.734-.54A11.635,11.635,0,0,1,9.74,5.676c.421-.2,1.418-1.888,3.048-1.888,1.426,0,2.887,1.358,3.532,2.041a1.645,1.645,0,0,0,.787.463,1.992,1.992,0,0,1,.813.41V2.764A2.764,2.764,0,0,0,15.156,0"
                  transform="translate(0.443 0.443)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>`
        },
        {
          name: 'endUser.mediaCenter',
          pathName: 'mediaCenter',
          icon: `<svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.282"
            height="17.799"
            viewBox="0 0 19.282 17.799"
          >
            <path
              id="event"
              d="M6,11.933V10.82A3.341,3.341,0,0,1,9.337,7.483H10.45V8.6a1.483,1.483,0,0,0,2.966,0V7.483h4.45V8.6a1.483,1.483,0,0,0,2.966,0V7.483h1.112a3.341,3.341,0,0,1,3.337,3.337v1.112ZM11.191,8.6a.742.742,0,0,0,1.483,0V6.742a.742.742,0,0,0-1.483,0Zm7.416-1.854V8.6a.742.742,0,0,0,1.483,0V6.742a.742.742,0,0,0-1.483,0Zm6.674,5.933v7.787A3.341,3.341,0,0,1,21.945,23.8H9.337A3.341,3.341,0,0,1,6,20.461V12.674ZM18.35,16.868H16.819l-.474-1.456a.742.742,0,0,0-1.409,0l-.473,1.456H12.931a.742.742,0,0,0-.436,1.342l1.239.9-.473,1.456a.742.742,0,0,0,1.141.829l1.238-.9,1.238.9a.742.742,0,0,0,1.141-.829l-.473-1.456,1.239-.9a.742.742,0,0,0-.436-1.342Z"
              transform="translate(-6 -6)"
              fill="#fff"
            />
          </svg>`
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.nav-desketop{
  display: none;
}
@media (min-width : 992px) {
  .nav-desketop{
    display: block;
  }
  .mobile-nav{
    display: none;
  }
}
</style>
