<template>
  <div class="main_menu">
    <div class="bg-primary">
        <div
          class="d-flex py-3 justify-content-between align-items-center gap_1 main-menu__wrapper"
        >
          <router-link tag="h2" :to="{path: '/'}" class="p-0 m-0 cursor-pointer">
        <!-- Logo -->
<!--            <h2 class="text-white logoText"  v-if="$route.name ==='insurance' || $route.name ==='registerInsurance'">-->
              <b-img
                  v-if="$route.name ==='insurance' || $route.name ==='registerInsurance'"
                  class="ibbilLogo"
                  :src="require('@/assets/images/ibbilInsuranceLogo.png')"
              ></b-img>
          <b-img
              v-else
              class="ibbilLogo"
              :src="require('@/assets/images/ibbil/logo.svg')"
          ></b-img>
            <!-- Website Pages links -->
          </router-link>
            <div>
              <ul class="m-0 p-0 d-flex justify-content-start gap_1">
                <router-link  v-for="(item, i) in mainMenu" :key="i" :to="{name: item.pathName}" tag="li" class="cursor-pointer">{{ $t(item.name) }}</router-link>
              </ul>
            </div>
            <seach />
          <div class="d-flex align-items-center">
            <div class="actions_nav">
              <ul class="ml-auto navbar-list d-flex align-items-center">
                <li class="select-lang text-black d-flex bg-white rounded py-1 px-2 nav-item" :class="selectLangPopUp ? 'bg-white text-black' : 'bg-transparent text-white'" @click="selectLangPopUp = !selectLangPopUp">
                    <ul class="d-flex flex-column align-items-center p-0">
                      <li class="d-flex justify-items-center align-items-center text-center">
                        <img :src="selectedLang.image" style="width : 25px; height:15px; margin-inline-end : 5px" alt="" class="border" >
                        <span>{{$t(selectedLang.title)}}</span>
                        <i class="las mx-2" :class="selectLangPopUp ? 'la-angle-up' : 'la-angle-down'"></i>
                      </li>
                      <li class="border-top" :class="!selectLangPopUp ? 'd-none' : 'd-block'"
                        @click="rtlChange(notSelectedLang[0].rtl);langChange(notSelectedLang[0]);">
                        <span style="font-size : 13px">{{$t(notSelectedLang[0].title)}}</span>
                        <img :src="notSelectedLang[0].image" style="width : 22px; height:12px; margin-inline-start : 5px" alt="" class="border" >
                      </li>
                    </ul>
                </li>
                <li class="nav-item" v-if="getUserInfo">
                  <router-link tag="span" :to="{name: 'user-home'}"
                      class="search-toggle iq-waves-effect language-title cursor-pointer">
                    <span class="text-white" >
                    {{ getUserInfo.name }}
                    </span>
                     <i class="las la-user text-white font-size-22"></i>
                  </router-link>
                </li>
                <li class="nav-item" v-else>
                  <router-link tag="span" :to="{name: 'login'}"
                    class="search-toggle iq-waves-effect language-title cursor-pointer"
                  >
                    <span class="text-white" >
                      {{ $t("main.login") }}
                    </span>
                    <i class="las la-user text-white font-size-22"></i>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{name: 'cart'}"
                    class="search-toggle iq-waves-effect language-title"
                  >
                    <span class="text-white">
                      {{ $t("main.cart") }}
                    </span>
                    <i class="las la-shopping-cart text-white font-size-22 position-relative"></i>
                    <div class="d-flex justify-content-end position-absolute cart-count" v-if="getProductsCart">
                      <span class="number-store-cart d-flex align-items-center justify-content-center">{{ getProductsCart }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import seach from './Search.vue'

export default {
  components: { seach },
  props: ['mainMenu'],
  data () {
    return {
      search: '',
      rtl: false,
      selectLangPopUp: false
    //   userData: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).data.user.name : ''
    }
  },
  computed: {
    ...mapGetters({
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      getUserInfo: 'getUserInfo'
    }),
    notSelectedLang () {
      return this.langsOptions.filter(lng => lng.title !== this.selectedLang.title)
    },
    getProductsCart () {
      return this.$store.getters['cart/getTotalProduct']
    }
  },
  methods: {
    langChange (lang) {
      this.langChangeState(lang)
      this.$i18n.locale = lang.value
      localStorage.setItem('lang', lang.value)
      window.location.reload()
      document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
      if (lang.value === 'ar') {
        this.rtlAdd(lang)
      } else {
        this.rtlRemove(lang)
      }
      console.log(lang)
    },
    rtlChange (mode) {
      this.rtl = mode
      this.modeChange({ rtl: mode, dark: this.darkMode })
    },
    ...mapActions({
      langChangeState: 'Setting/setLangAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction',
      modeChange: 'Setting/layoutModeAction'
    })
  },
  mounted () {
    console.log(this.selectedLang)
  }
}
</script>

<style>
.cart-count{
  top: 5px;
  left: 25px;
}
.number-store-cart {
  background: #D62020;
  border-radius: 50%;
  font-size: 12px;
  width: 20px;
  height: 20px;
  color: #fff;
  font-weight: bold;
}
.main-menu__wrapper{
  width: 85%;
  margin: auto;
}
.select-lang{
  transition: 0.3s ease-in-out;
}
.select-lang li{
  cursor: pointer;
}
.user-img img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
}
@media (min-width: 990px) and (max-width: 1020px){
  .main-menu__wrapper {
    width: 96% !important;
    margin: auto;
    zoom: 77%;
  }
}
@media (min-width: 1021px) and (max-width: 1400px){
  .main-menu__wrapper {
    width: 96% !important;
    margin: auto;
    zoom: 79%;
  }
}
@media (min-width: 1401px){
  .main-menu__wrapper {
    width: 90% !important;
    margin: auto;
  }
}
.logoText {
  font-weight: bolder;
}
</style>
